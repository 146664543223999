import request from '../request'

export function fetchList(params) {
    return request({
        url: '/Api/Company/getList',
        params,
    })
}

export function fetchDetail(params) {
    return request({
        url: '/Api/Company/getDetail',
        params
    })
}