<template>
  <div class="job-list">
    <div class="job-item" v-for="(it, index) in list" :key="index" @click="route(it)">
      <div class="job-header">
        <div class="job-title">{{ L(it.name) }}</div>
        <div class="job-salary">{{ L("招聘") }} {{ it.plan_num }} 人</div>
      </div>
      <div class="job-education">{{ L(it.job_detail) }}</div>
      <div class="job-tags">
        <div :title="L('专业')">
          <i class="ri-book-mark-line ri-fw"></i> {{ L(it.major) }}
        </div>
        <div :title="L('类型')">
          <i class="ri-shield-user-line ri-fw"></i> {{ L(it.type) }}
        </div>
        <div :title="L('住宿')">
          <i class="ri-home-smile-line ri-fw"></i> {{ L(it.room_detail) }}
        </div>
        <div v-if="it.salary > 0" :title="L('实习薪资')">
          <i class="ri-money-cny-circle-line ri-fw"></i>
          <span v-if="it.salary > 0">{{ it.salary }}{{ L("元/月") }}</span>
          <span v-else>{{ L(it.salary) }}</span>
        </div>
        <div v-if="it.salary_detail" :title="L('薪资')">
          <i class="ri-money-cny-circle-line ri-fw"></i>
          <span>{{ it.salary_detail }}</span>
        </div>
        <div v-if="it.insure_detail" :title="L('保险')">
          <i class="ri-shield-user-line ri-fw" :title="L('保险')"></i>
          {{ L(it.insure_detail) }}
        </div>
        <div v-if="it.meal_detail" :title="L('伙食')">
          <i class="ri-cake-3-line ri-fw" :title="L('伙食')"></i>
          {{ L(it.meal_detail) }}
        </div>
        <div v-if="it.probation_detail" :title="L('实习期')">
          <i class="ri-calendar-2-line ri-fw" :title="L('实习期')"></i>
          {{ L(it.probation_detail) }}
        </div>
      </div>
      <div class="user">
        <img v-if="it.company_logo_img" :src="it.company_logo_img" class="logo" alt="" />
        <div v-else class="logo">
          <span v-text="L(it.company_short_name)"></span>
        </div>
        <div style="flex: 1">
          <div class="user-title" v-text="L(it.company_name)"></div>
          <div class="user-description">
            <span class="gray">{{ L(it.company_type) }} | {{ L(it.company_city) }} |
              {{ L(it.company_area) }}</span>
            <span>{{ L("共") }} {{ it.company_job_num }} {{ L("岗位") }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    route(item) {
      this.$router.push("/job-detail?uuid=" + item.uuid);
    },
  },
};
</script>

<style lang="less" scoped>
.job-item {
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 16px;
  transition: all 0.3s;
  cursor: pointer;
  margin-bottom: 16px;
}

.job-item:hover {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
}

.job-item .job-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.job-item .job-title {
  width: 90%;
  font-size: 18px;
  color: #004BB0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.job-item .job-salary {
  flex-shrink: 0;
  font-size: 15px;
  color: #fabb1c;
}

.job-item .job-education {
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.job-item .job-tags {
  padding-bottom: 4px;
  margin: 12px 0;
  //border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
  display: flex;
  flex-wrap: wrap;
}

.job-item .job-tags>div {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 60px;
  height: 25px;
  padding: 0 8px;
  box-sizing: border-box;

  margin-bottom: 8px;
  margin-right: 8px;
  background: rgba(32, 49, 127, 0.05);
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.job-item .user {
  display: flex;
  align-items: center;
}

.job-item .user .logo {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  margin-right: 10px;
  text-align: center;
  letter-spacing: 0.1em;
  background-color: #004BB0;
  color: #fff;
  overflow: hidden;
}

.job-item .user .user-title {
  font-size: 16px;
  color: #000000;
  margin-bottom: 4px;
}

.job-item .user .user-description {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.8);
}
</style>