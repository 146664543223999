<template>
  <div>
    <div
      class="company-item"
      v-for="(it, index) in list"
      :key="index"
      @click="route(it)"
    >
      <div class="header">
        <img
          class="company-logo"
          v-if="it.logo_img"
          :src="it.logo_img"
          alt=""
        />
        <div v-else class="company-logo">
          <span v-text="L(it.short_name)"></span>
        </div>

        <div>
          <div class="company-title">{{ L(it.name) }}</div>
          <div style="font-size: 12px" class="gray">
            {{ L(it.city) }} | {{ L(it.area) }}
          </div>
        </div>
      </div>

      <div class="company-business">{{ L(it.business) }}</div>
      <div class="company-info">
        <div class="company-info-item">
          <div class="value">{{ L(it.type) }}</div>
          <div>{{ L("企业类型") }}</div>
        </div>
        <!-- <div class="company-info-item">
          <div class="value">{{ it.employee_num }}</div>
          <div>{{ L("职员人数") }}</div>
        </div> -->
        <div class="company-info-item">
          <div class="value">{{ it.job_num }}</div>
          <div>{{ L("招聘职位") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    route(item) {
      this.$router.push("/company-detail?uuid=" + item.uuid);
    },
  },
};
</script>

<style lang="less" scoped>
.company-item {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: all 0.3s;
  cursor: pointer;
  padding: 16px;
  margin-bottom: 16px;
}
.company-item:hover {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
}

.company-item .header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.company-item .company-logo {
  width: 67px;
  height: 67px;
  margin-right: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  background-color: #004BB0;
  color: #fff;
  letter-spacing: 0.1em;
}

.company-item .company-title {
  font-size: 16px;
  color: #004BB0;
  margin-bottom: 8px;
}

.company-item .company-business {
  font-size: 13px;
  margin-bottom: 16px;
}

.company-item .company-info {
  display: flex;
  padding-bottom: 24px;
}
.company-item .company-info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  position: relative;
  margin-right: 40px;
}
.company-item .company-info-item::after {
  content: "";
  position: absolute;
  top: 20%;
  bottom: 60%;
  right: -20px;
  width: 1px;
  background-color: #000;
}
.company-item .company-info-item:last-child {
  margin-right: 0;
}
.company-item .company-info-item:last-child::after {
  width: 0;
}
.company-item .company-info-item .value {
  font-size: 20px;
  color: #fabb1c;
  margin-bottom: 8px;
}
</style>