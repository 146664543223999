<template>
  <div class="search-page">
    <div class="search-wrapper">
      <div class="container">

        <HotSearch :configInfo="configInfo" @change="payload => {
          keyword = payload.searchValue;
          if (payload.searchCategory) {
            selectedMajorList = [payload.searchCategory];
          }
          search()
        }" />
        

        <!-- <div class="search">
          <input
            type="text"
            v-model="keyword"
            :placeholder="L('请输入职位、公司或关键词')"
            @keydown.enter="search"
          />
          <img src="@/assets/icon-search.png" alt="" @click="search" />
        </div> -->


      </div>
    </div>

    <div class="container">
      <div class="category-list">
        <div class="category">
          <div class="label">{{ L("专业") }}</div>
          <div class="values">
            <div
              v-for="(item, index) in typeList"
              :key="index"
              :class="selectedMajorList.indexOf(item) > -1 ? 'selected' : ''"
              @click="selectMajor(item)"
            >
              {{ L(item) }}
            </div>
          </div>
        </div>
        <div class="category">
          <div class="label">{{ L("类型") }}</div>
          <div class="values">
            <div
              v-if="type !== 'intern'"
              :class="selectedTypeList.indexOf('') > -1 ? 'selected' : ''"
              @click="selectType('')"
            >
              {{ L("不限类型") }}
            </div>
            <div
              :class="selectedTypeList.indexOf('实习') > -1 ? 'selected' : ''"
              @click="selectType('实习')"
            >
              {{ L("实习") }}
            </div>
    
            <div
              :class="selectedTypeList.indexOf('长期实习') > -1 ? 'selected' : ''"
              @click="selectType('长期实习')"
            >
              {{ L("长期实习") }}
            </div>

            <div
            v-if="type !== 'intern'"
              :class="
                selectedTypeList.indexOf('实习转就业') > -1 ? 'selected' : ''
              "
              @click="selectType('实习转就业')"
            >
              {{ L("实习转就业") }}
            </div>

            <div
            v-if="type !== 'intern'"
              :class="selectedTypeList.indexOf('就业') > -1 ? 'selected' : ''"
              @click="selectType('就业')"
            >
              {{ L("就业") }}
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="search-list">
      <div class="container">
        <div class="tab-bar">
          <div
            class="tab"
            :class="active === 'job' ? 'active' : ''"
            @click="changeTab('job')"
          >
            {{ L("职缺") }}
          </div>
          <div
            class="tab"
            :class="active === 'company' ? 'active' : ''"
            @click="changeTab('company')"
          >
            {{ L("公司") }}
          </div>
        </div>

        <div v-if="list.length > 0">
          <template v-if="active === 'job'">
            <Job :list="list" />
          </template>
          <template v-if="active === 'company'">
            <Company :list="list" />
          </template>

          <div class="center">
            <a-pagination
              v-model="current"
              :pageSize="pageSize"
              :total="total"
            />
          </div>
        </div>
        <div v-else>
          <a-empty />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchList as fetchJobList } from "@/api/job";
import { fetchList as fetchCompanyList } from "@/api/company";
import Job from "./components/job.vue";
import Company from "./components/company.vue";
import { fetchConfig } from "@/api/config";
import HotSearch from "./components/hot-search.vue";
export default {
  components: {
    Job,
    Company,
    HotSearch,
  },

  data() {
    return {
      keyword: "",
      active: "job",
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      configInfo: {},
      selectedMajorList: [],
      selectedTypeList: [],

      type: '',
    };
  },

  watch: {
    current() {
      this.getList();
    },
    "$route.query.keyword"() {
      console.log("reload ");
      this.getData();
    },
  },

  computed: {
    typeList() {
      return typeof this.configInfo.MAJOR_TYPE === "string"
        ? this.configInfo.MAJOR_TYPE.split(",")
        : [];
    },
  },

  mounted() {
    this.type = window.localStorage.getItem('type') || ""

    if (this.type === 'intern') {
      this.selectedTypeList = ["实习"];
    }

    this.getData();

    fetchConfig().then((res) => {
      this.configInfo = res;
    });
  },
  methods: {
    getData() {
      const { query } = this.$route;
      const { keyword, major } = query || {};
      if (keyword) {
        this.keyword = keyword;
        if (major) {
          const index = this.selectedMajorList.indexOf(major);
          if (index === -1) {
            this.selectedMajorList.push(major);
          }
        }
        this.getList();
      }

      if (!major && !keyword) {
        this.changeTab("job");
      }
    },
    getList() {
      const { current, pageSize } = this;

      if (this.active === "job") {
        this.loading = true;

        fetchJobList({
          page: current,
          rows: pageSize,
          keyword: this.L2(this.keyword),
          major:
            this.selectedMajorList.length > 0
              ? this.L2(this.selectedMajorList.join(","))
              : undefined,
          type:
            this.selectedTypeList.length > 0
              ? this.L2(this.selectedTypeList.join(","))
              : undefined,
        })
          .then((res) => {
            if (Array.isArray(res.list)) {
              this.list = res.list;
              this.total = parseInt(res.total);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.loading = true;

        fetchCompanyList({
          page: current,
          rows: pageSize,
          keyword: this.L2(this.keyword),
          // keyword: this.keyword,
        })
          .then((res) => {
            if (Array.isArray(res.list)) {
              this.list = res.list;
              this.total = parseInt(res.total);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    search() {
      this.getList();
    },

    changeTab(tab) {
      this.active = tab;
      this.current = 1;
      this.list = [];
      this.getList();
    },

    selectMajor(value) {
      const index = this.selectedMajorList.indexOf(value);

      if (value === "不限专业") {
        if (index === -1) {
          this.selectedMajorList = ["不限专业"];
        } else {
          this.selectedMajorList.splice(index, 1);
        }
      } else {
        if (index === -1) {
          this.selectedMajorList.push(value);
          const idx = this.selectedMajorList.indexOf("不限专业");
          if (idx > -1) {
            this.selectedMajorList.splice(idx, 1);
          }
        } else {
          this.selectedMajorList.splice(index, 1);
        }
      }

      this.getList();
    },
    selectType(value) {

      
      const index = this.selectedTypeList.indexOf(value);

      console.log('value', value, index, this.type)
      if (value === "") {
        if (index === -1) {
            this.selectedTypeList = [""];
        } else {
            this.selectedTypeList.splice(index, 1);
        }
      } else {
        if (index === -1) {
          this.selectedTypeList.push(value);
          this.selectedTypeList = this.selectedTypeList.filter((item) =>
            item.trim()
          );
        } else {

          if (this.type === 'intern' && this.selectedTypeList.length === 1) {
            this.$message.error(window.L("请选择实习类型"));
          }  else {
            this.selectedTypeList.splice(index, 1);

          }

        }
      }

      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.search-page {
  flex: 1;
  background-color: #f3f3f3;
}
.search-wrapper {
  background: #004BB0;
}
.search-wrapper .container {
  display: flex;
  align-items: center;
  padding: 24px 0;
}

.search {
  flex: 1;
  display: flex;
  background: #ffffff;
  border-radius: 5px;
}
.search input {
  flex: 1;
  padding: 12px;
  border-width: 0;
  outline: none;
  font-size: 24px;
  font-weight: 400;
  color: #000000;
  opacity: 0.8;
}
.search input::placeholder {
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  opacity: 0.2;
}
.search img {
  margin-top: 12px;
  margin-right: 12px;
  height: 35px;
  width: 35px;
  cursor: pointer;
}

.search-list {
  padding-top: 24px;
  padding-bottom: 36px;
}

.search-list .container {
  padding: 12px;
  background-color: #fff;
}

.tab-bar {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
}
.tab {
  font-weight: 400;
  color: #000000;
  opacity: 0.6;
  cursor: pointer;
  margin-right: 16px;
  padding-bottom: 10px;
}
.active {
  color: #004BB0;
  opacity: 1;
  position: relative;
}
.active::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 1.5px;
  background-color: #004BB0;
}

.category-list {
  padding: 16px 12px 4px;
  margin-top: 24px;
  background-color: #fff;
}
.category {
  display: flex;
  align-items: baseline;
}
.category .label {
  font-weight: bold;
  margin-right: 24px;
  flex-shrink: 0;
}
.category .values {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
}
.category .values div {
  margin-right: 16px;
  line-height: 2em;
  padding: 0 4px;
  cursor: pointer;
  margin-bottom: 12px;
  border: 1px solid #fff;
}
.category .values .selected {
  // background-color: #004BB0;
  // color: #fff;
  border: 1px solid #004BB0;
  color: #004BB0;
}
</style>