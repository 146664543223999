<template>
    <div class="search-panel">
      <div class="search">
        <div class="selector">
          <div style="display: flex; align-items: center" @click="categorySelectorVisible = !categorySelectorVisible">
            <span class="text">{{
              searchCategory ? L(searchCategory) : L("岗位专业")
            }}</span>
            <div style="transition: all 0.3s" :class="categorySelectorVisible ? 'rotate' : ''">
              <i class="iconfont icon-arrow-down-bold"></i>
            </div>
          </div>
  
          <div class="job-category" v-if="categorySelectorVisible">
            <div v-for="item in typeList" :key="item" :class="searchCategory === item ? 'active' : ''" @click="onSelect(item)">
              {{ L(item) }}
            </div>
          </div>
        </div>
        <input type="text" :placeholder="L('请输入职位、公司或关键词')" v-model="searchValue" @keydown.enter="search" />
        <img src="@/assets/icon-search.png" alt="" @click="search" />
      </div>

 
    </div>
  </template>
  
  <script>
  export default {
  
    props: {
      configInfo: {
        type: Object,
        default: () => ({}),
      },
    },
  
    data() {
      return {
        searchValue: "",
        searchCategory: "",
        categorySelectorVisible: false,
      };
    },
  
    computed: {
      hotSearch() {
        return typeof this.configInfo.HOT_SEARCH === "string"
          ? this.configInfo.HOT_SEARCH.split(",")
          : [];
      },
  
      typeList() {
        return typeof this.configInfo.MAJOR_TYPE === "string"
          ? this.configInfo.MAJOR_TYPE.split(",")
          : [];
      },
    },
  
    methods: {
            onSelect(item) {
                this.searchCategory = item;
                this.categorySelectorVisible = false;
            },
       
      search() {
        if (!this.searchValue) return;
        this.send({
          searchValue: this.searchValue,
          searchCategory: this.searchCategory,
        });
      },

      send(value) {
        this.$emit("change", value)

},

    },
  };
  </script>
  
  <style lang="less" scoped>
  .search-panel {
    position: relative;
    width: 100%;
  }

  
  .search {
    padding: 10px;
    display: flex;
    background: #ffffff;
    border: 2px solid rgba(0, 0, 0, 0.0784313725490196);
    border-radius: 5px;
  }
  
  .search .selector {
    flex-shrink: 0;
    padding-left: 6px;
    margin-right: 54px;
    display: flex;
    align-items: center;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
    cursor: pointer;
    position: relative;
  }
  
  .search .selector .text {
    margin-right: 24px;
  }
  
  .search .selector .iconfont {
    color: rgba(0, 0, 0, 0.3);
  }
  
  .search .selector .rotate {
    transform: rotate(-180deg);
  }
  
  .search .selector::after {
    content: "";
    position: absolute;
    top: 30%;
    bottom: 30%;
    right: -24px;
    width: 1.5px;
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .search .selector .job-category {
    position: absolute;
    z-index: 99;
    top: 44px;
    left: 0;
    width: 150px;
    background-color: #fff;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    padding: 12px;
  }
  
  .search .selector .job-category>div {
    font-size: 13px;
    padding: 4px;
  }
  
  .search .selector .job-category>div:hover {
    background-color: rgba(0, 0, 0, 0.03);
    color: #000;
  }
  
  .search .selector .job-category .active {
    background-color: #004BB0;
    color: #fff;
  }
  
  .search input {
    flex: 1;
    border-width: 0;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    opacity: 0.8;
  }
  
  .search input::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    opacity: 0.2;
  }
  
  .search img {
    height: 35px;
    width: 35px;
    cursor: pointer;
  }
  
  .hot-search {
    margin-bottom: 58px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
  }
  
  .hot-search a {
    display: inline-block;
    margin-right: 20px;
    color: #004BB0;
  }
  
  @media screen and (max-width: 767px) {
    .search-panel {
      // overflow: hidden;
    }
  
    .search .selector {
      font-size: 14px;
      flex-shrink: 0;
      margin-right: 6px;
    }
  
    .search .selector .text {
      margin-right: 4px;
    }
  
    .search .selector::after {
      content: "";
      width: 0;
    }
  }
  </style>